<script>
import { defineComponent } from 'vue'
import Steps from './components/Steps.vue'

export default defineComponent({
  name: 'App',
  components: { Steps },
})

</script>

<template>
  <Steps />
</template>

